//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import '@/components/icons/payments';
import { preFetchPage } from '@/helpers/router';
import Btn from '@/components/Controls/Btn';
import SectionHeader from '@/components/Controls/SectionHeader';
import PaymentsTable from '@/components/Seo/PaymentsTable';
export default {
  name: 'Payments',
  preFetch: preFetchPage,
  components: {
    'app-btn': Btn,
    'app-section-header': SectionHeader,
    'app-payments-table': PaymentsTable
  },
  props: {
    config: Object
  },
  data: function data() {
    return {
      tab: 'deposit',
      toggleTable: false
    };
  },
  meta: function meta() {
    try {
      return {
        title: this.seoPage.data.seo.title,
        meta: {
          description: {
            name: 'description',
            content: this.seoPage.data.seo.description
          },
          keywords: {
            name: 'keywords',
            content: this.seoPage.data.seo.keywords
          }
        }
      };
    } catch (e) {
      this.$store.log(e);
      return {};
    }
  },
  computed: {
    columns: function columns() {
      return [{
        name: 'name',
        required: true,
        label: this.$t('payments.option'),
        align: 'left',
        field: function field(row) {
          return row.logo;
        },
        format: function format(val) {
          return "".concat(val);
        },
        sortable: false
      }, {
        name: 'commission',
        align: 'left',
        label: this.$t('payments.commission'),
        field: 'commission',
        sortable: false
      }, {
        name: 'time',
        label: this.$t('payments.time'),
        field: 'time',
        sortable: false,
        align: 'left'
      }, {
        name: 'currency',
        label: this.$t('payments.currency'),
        field: 'currencies',
        sortable: false,
        align: 'left'
      }];
    },
    seoPage: function seoPage() {
      return this.$store.getters['statics/seoPage'];
    },
    lang: function lang() {
      return this.$store.getters['lang/lang'];
    },
    defaultLang: function defaultLang() {
      return this.$store.getters['lang/defaultLang'];
    },
    sections: function sections() {
      return this.$store.getters['sidebar/pageSections'];
    },
    screenWidth: function screenWidth() {
      return this.$q.screen.width;
    }
  },
  methods: {
    linkToMainPage: function linkToMainPage() {
      return this.lang === this.defaultLang ? '/' : "/".concat(this.lang);
    },
    preparePayments: function preparePayments(arr) {
      return arr.forEach(function (item) {
        item.currencies = item.currencies.join('/');
      });
    }
  }
};