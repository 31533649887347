//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    payments: Array,
    columns: Array
  },
  data: function data() {
    return {
      pagination: {
        page: 1,
        rowsPerPage: this.payments.length
      }
    };
  },
  computed: {
    screenWidth: function screenWidth() {
      return this.$q.screen.width;
    }
  }
};